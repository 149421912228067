<template>
  <button
    class="group relative w-full max-w-lg p-3 rounded-3xl bg-blue-300 overflow-hidden shadow-black hover:drop-shadow-md transition-all duration-300 md:py-5 md:px-7"
    @click="handleOnClick"
  >
    <!-- Background graphic for desktop -->
    <FrontendIllustrationCardWaveBg
      class="hidden absolute top-0 left-0 w-full h-2/5 -translate-y-4 object-cover group-hover:translate-y-0 transition-height duration-300 md:block"
      :color="color"
    />

    <!-- Background graphic for mobile -->
    <FrontendIllustrationCardWaveBgMobile
      class="absolute -bottom-3 left-0 w-full object-cover h-2/5 md:hidden"
      :color="color"
    />

    <!-- Text and Image -->
    <div class="relative flex flex-row-reverse justify-between items-center z-10 md:block">
      <img
        v-if="src"
        :src="src"
        class="w-[29%] md:h-[80px] lg:h-[120px] xl:h-[162px] 2xl:h-[200px] md:mx-auto md:w-1/2"
        :alt="alt"
      />
      <h2 class="font-bold text-pretty text-white text-base md:text-2xl">
        {{ heading }}
        <br v-if="subText" /><span v-if="subText" class="font-normal">{{ subText }}</span>
        <UIcon
          name="heroicons:arrow-right-circle"
          class="size-4 ml-1 -mb-2 group-hover:translate-x-2 group-hover:opacity-80 transition-all duration-300 md:size-7"
        />
      </h2>
    </div>
  </button>
</template>
<script setup lang="ts">
const router = useRouter()
const props = defineProps({
  color: {
    type: String as PropType<'blue' | 'green' | 'pink'>,
    default: 'blue'
  },
  src: {
    type: String,
    required: false,
    default: ''
  },
  heading: {
    type: String,
    required: true
  },
  subText: {
    type: String,
    required: false,
    default: ''
  },
  to: {
    type: String,
    required: true
  },
  callback: {
    type: Function,
    required: false,
    default: null
  },
  alt: {
    type: String,
    required: true
  }
})

const handleOnClick = () => {
  if (props.callback) {
    props.callback()
  }
  // Redirect to the link
  router.push(props.to)
}
</script>
