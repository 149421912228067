<template>
  <div class="relative" :class="{ 'bg-blue grow': isNative }">
    <!-- Hero -->
    <FrontendHomepageHeroBlock />

    <div v-if="!isNative">
      <!-- How it works -->
      <FrontendHowItWorksBlock />

      <!-- App store block -->
      <FrontendAppStoreBlock />
    </div>
  </div>
</template>
<script setup lang="ts">
const { isNative } = useRuntimeConfig().public

useSeoMeta({
  title: 'Mental Health Support Network provided by Chasing the Stigma | Hub of hope',
  description:
    'Chasing the Stigma (CTS) has launched the Hub of Hope - a national mental health database, bringing help and support together in one place, with a focus on grassroots organisations.'
})

// get sncs in the background so that they're ready for search
// these should be heavily cached so that they're not fetched on every page load
onMounted(() => {
  useSupportNeedCategoryStore().fetchSupportNeedCategories()
})
</script>
