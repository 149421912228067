<template>
  <div class="grid gap-7 pt-5 pb-24 justify-items-center grid-cols-1 md:grid-cols-3 md:pt-14">
    <FrontendIllustrationCard
      v-for="(card, i) in cards"
      :key="i"
      :color="card.color"
      :src="card.src"
      :heading="card.heading"
      :sub-text="card.subText"
      :to="card.to"
      :callback="card.callback"
      :alt="card.alt"
    />
  </div>
</template>
<script setup lang="ts">
import { youthAgeRange, adultAgeRange } from '~/composables/useAgeBrackets'

const youthSupportHeading = 'Support for young people'
const adultSupportHeading = 'Support for adults'
const emergencySupportHeading = 'Urgent help in an emergency'

const serviceSearchStore = useServiceSearchStore()
const { clearFilters, clearCache } = serviceSearchStore
const { filters } = storeToRefs(serviceSearchStore)

const filterServicesForYoungPeople = () => {
  clearCache()
  clearFilters({ keep: ['distance', 'sortCol', 'sortDirection', 'coordinatesLat', 'coordinatesLon'] })
  filters.value.ageRange = [...youthAgeRange]
}
const filterServicesForAdults = () => {
  clearCache()
  clearFilters({ keep: ['distance', 'sortCol', 'sortDirection', 'coordinatesLat', 'coordinatesLon'] })
  filters.value.ageRange = [...adultAgeRange]
}

const onYoungPeopleClick = () => {
  filterServicesForYoungPeople()
  useGaEvent('home_page_search_shortcut', {
    ageRange: [...youthAgeRange],
    card: 'youth',
    cardText: youthSupportHeading
  })
}

const onAdultClick = () => {
  filterServicesForAdults()
  useGaEvent('home_page_search_shortcut', {
    ageRange: [...adultAgeRange],
    card: 'adults',
    cardText: adultSupportHeading
  })
}

const onEmergencyClick = () => {
  useGaEvent('home_page_search_shortcut', {
    card: 'emergency',
    cardText: emergencySupportHeading,
    ageRange: null
  })
}

const cards: {
  color: 'blue' | 'green' | 'pink'
  src?: string
  heading: string
  subText?: string
  to: string
  callback?: () => void
  alt: string
}[] = [
  {
    color: 'blue',
    src: '/static/illustration-card/young-person-new.svg',
    heading: youthSupportHeading,
    subText: '(25 & under)',
    to: '/services',
    callback: onYoungPeopleClick,
    alt: 'Illustration of a young person'
  },
  {
    color: 'green',
    src: '/static/illustration-card/adult-new.svg',
    heading: adultSupportHeading,
    to: '/services',
    callback: onAdultClick,
    alt: 'Illustration of an adult'
  },
  {
    color: 'pink',
    src: '/static/illustration-card/emergency-new.svg',
    heading: emergencySupportHeading,
    to: '/need-help-now',
    callback: onEmergencyClick,
    alt: 'Illustration of an emergency'
  }
]
</script>
