<template>
  <section class="relative bg-blue text-white pt-12 pb-8 overflow-hidden xl:pt-16">
    <!-- SVG top border -->
    <BlockBorderGraphic class="absolute -top-1 left-0 w-full" />
    <div class="container items-center gap-2.5 md:flex">
      <div class="md:w-1/2 mt-20">
        <h2 class="text-white font-bold mb-5 text-2xl md:text-3xl">
          Download the FREE Hub of Hope app for iOS or Android
        </h2>
        <ul class="font-poppins list-disc ml-5 mb-8 lg:mb-16">
          <li class="mb-2">Find local services on the go using geolocation technology</li>
          <li class="mb-2">Mental health support at your fingertips, wherever you go</li>
          <li class="mb-2">Access urgent help fast</li>
          <li>Find help for others wherever you are</li>
        </ul>
        <div class="md:pl-3">
          <FrontendAppStoreLinks />
        </div>
      </div>
      <div class="md:w-1/2">
        <img
          src="/static/app-store-block-featured-image-v3.jpg"
          alt="Mobile phones graphic displaying Hub of hop on app store"
          class="relative -bottom-12 md:-bottom-28 lg:-bottom-52"
          width="600"
          height="500"
        />
      </div>
    </div>
  </section>
</template>
