<template>
  <section
    class="relative bg-blue text-white overflow-hidden pt-10 md:pt-24 md:pb-20"
    :class="{ 'pb-12': !isNative }"
  >
    <!-- Heading and Illustration Card -->
    <div class="container relative z-10">
      <FrontendHeadingWithFlare
        title="There is always hope"
        description="We are the UK’s largest mental health support directory. Whether you want to find a service to help you feel better or you want to support someone who is struggling, we can help."
      />
      <FrontendIllustrationCards />
    </div>

    <!-- Bottom Border Graphic-->
    <BlockBorderGraphic v-if="!isNative" class="absolute -bottom-1 left-0 w-full rotate-180 z-10" />

    <!-- Background Circles -->
    <div
      aria-hidden="true"
      class="hidden absolute bottom-[43%] -left-10 size-80 rounded-full bg-blue-400 lg:block"
    ></div>
    <div
      aria-hidden="true"
      class="hidden absolute size-[27rem] top-4 -right-32 rounded-full bg-blue-400 lg:block"
    ></div>
    <div
      aria-hidden="true"
      class="hidden absolute size-[13.6rem] -bottom-20 right-[20%] rounded-full bg-blue-400 lg:block"
    ></div>
  </section>
</template>

<script lang="ts" setup>
const { isNative } = useRuntimeConfig().public
</script>
