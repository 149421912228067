<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 389 97" fill="none" class="w-full">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M389 62.9004L389 0L0 0L0 80.8644C82.2148 97.0172 213.995 104.551 277.796 86.3547C312.591 76.4312 352.348 68.1744 389 62.9004Z"
        :class="fill"
      />
    </svg>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  color: {
    type: String as PropType<'blue' | 'green' | 'pink'>,
    default: 'blue'
  }
})

const fill = computed(() => {
  if (props.color === 'blue') return 'fill-calmBlue'
  if (props.color === 'green') return 'fill-green'
  if (props.color === 'pink') return 'fill-pink'
  // Default to blue
  return 'fill-blue'
})
</script>
