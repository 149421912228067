<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 31" fill="none" class="w-full">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M288 0.968933V16.0207C288 23.7527 281.732 30.0207 274 30.0207H0V18.9391C44.386 19.4501 149.068 12.83 227.443 5.06487C243.649 3.45919 264.464 2.09534 288 0.968933Z"
        :class="fill"
      />
    </svg>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  color: {
    type: String as PropType<'blue' | 'green' | 'pink'>,
    default: 'blue'
  }
})

const fill = computed(() => {
  if (props.color === 'blue') return 'fill-calmBlue'
  if (props.color === 'green') return 'fill-green'
  if (props.color === 'pink') return 'fill-pink'
  // Default to blue
  return 'fill-blue'
})
</script>
